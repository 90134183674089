<template>
  <div class="view-admin-guidance-external-link">
    <loading-screen :is-loading="isDeleting"></loading-screen>

    <!-- Is Loading -->
    <div v-if="isLoadingCombined" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

    <div class="page-header" v-if="!isLoadingCombined">
      <h1 class="page-title">
        External Link: {{ externalLink["$v"]["displayName"] }}
      </h1>
      <div class="actions">
        <a-button
          class="button-margin-left btn-rounded"
          icon="arrow-left"
          size="large"
          type=""
          @click.prevent="goBack"
          >Back</a-button
        >

        <a-button
          v-if="canEdit"
          class="button-margin-left btn-rounded"
          icon="edit"
          size="large"
          type="primary"
          @click.prevent="edit"
          >Edit</a-button
        >

        <a-popconfirm
          v-if="canEdit"
          title="Are you sure?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="attemptDelete"
        >
          <a-button
            class="button-margin-left btn-rounded"
            icon="delete"
            size="large"
            type="danger"
            >Delete</a-button
          >
        </a-popconfirm>
      </div>
    </div>

    <!-- Tabs -->
    <div v-if="!isLoadingCombined">
      <content-link-tabs
        :tenant-id="tenantId"
        :org-id="selectedOrganisation.id"
        :parent-id="externalLink['$v'].id"
        parent-type="External Link"
        :content-links="contentLinks"
        :is-loading="isLoadingContentLinks"
        :types="['articles']"
        :children-of="['articles']"
        :can-edit="canEdit"
        @refresh="loadContentLinks"
        :show-details-tab="true"
      >
        <a-button @click.prevent="openExternalLink" type="primary" icon="link"
          >Open link</a-button
        >
      </content-link-tabs>
    </div>
    <!-- / Tabs -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
const _ = require("lodash");
import LoadingScreen from "../../../../components/LoadingScreen.vue";
import externalLinks from "../../../../api/guidance/external-links";
import contentLinks from "../../../../api/guidance/content-links";
import ContentLinkTabs from "../../../../components/Admin/Guidance/ContentLinkTabs.vue";

export default {
  components: { LoadingScreen, ContentLinkTabs },

  data() {
    return {
      isLoadingExternalLink: true,
      externalLink: null,

      isDeleting: false,

      isLoadingParentContentLinks: false,
      parentContentLinks: [],

      isLoadingChildrenContentLinks: false,
      childrenContentLinks: [],
    };
  },

  created() {
    this.loadExternalLink();
    this.loadContentLinks();
  },

  methods: {
    goBack() {
       if (this.$route.query.backTo) {
        this.$router.push(this.$route.query.backTo);
      } else {
        this.$router.push("/admin/guidance/external-links");
      }
    },

    edit() {
      this.$router.push(
        "/admin/guidance/external-links/" + this.externalLinkId + "/edit"
      );
    },

    openExternalLink() {
      window.open(this.externalLink["$v"]["url"]);
    },

    loadExternalLink() {
      let vm = this;
      vm.isLoadingExternalLink = true;
      externalLinks
        .getExternalLink(this.tenantId, this.externalLinkId)
        .then((r) => {
          vm.isLoadingExternalLink = false;
          vm.externalLink = r.data;
        })
        .catch((e) => {
          console.log(e);
          this.$message.info("External link not found");
          this.$router.push("/admin/guidance/external-links");
        });
    },

    loadContentLinks() {
      this.loadChildrenContentLinks();
      this.loadParentContentLinks();
    },

    loadChildrenContentLinks() {
      let vm = this;
      vm.isLoadingChildrenContentLinks = true;
      contentLinks
        .getContentLinksForOrgByParentEntity(
          vm.tenantId,
          vm.selectedOrganisation.id,
          vm.externalLinkId
        )
        .then((r) => {
          vm.isLoadingChildrenContentLinks = false;
          vm.childrenContentLinks = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingChildrenContentLinks = false;
        });
    },

    loadParentContentLinks() {
      let vm = this;
      vm.isLoadingParentContentLinks = true;
      contentLinks
        .getContentLinksForOrgByChildEntity(
          vm.tenantId,
          vm.selectedOrganisation.id,
          vm.externalLinkId
        )
        .then((r) => {
          vm.isLoadingParentContentLinks = false;
          vm.parentContentLinks = r.data;
        })
        .catch((e) => {
          console.log(e);
          vm.isLoadingParentContentLinks = false;
        });
    },

    attemptDelete() {
      let vm = this;
      vm.isDeleting = true;
      externalLinks
        .deleteExternalLink(vm.tenantId, vm.externalLinkId)
        .then(() => {
          vm.isDeleting = false;
          vm.$message.success("External link deleted successfully");
          vm.$router.push("/admin/guidance/external-links");
        })
        .catch((e) => {
          vm.isDeleting = false;
          console.log(e);
          vm.$message.error("Error deleting external link");
        });
    },
  },

  computed: {
    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
      isLoading: "isLoading",
    }),

    ...mapGetters("missionControl", {
      idsOfOrganisationsIAmAnAdminFor: "idsOfOrganisationsIAmAnAdminFor",
    }),

    isLoadingContentLinks() {
      return (
        this.isLoadingParentContentLinks || this.isLoadingChildrenContentLinks
      );
    },

    contentLinks() {
      return _.unionBy(
        this.parentContentLinks,
        this.childrenContentLinks,
        "$v.id"
      );
    },

    externalLinkId() {
      return this.$route.params.id;
    },

    isLoadingCombined() {
      return this.isLoading || this.isLoadingExternalLink;
    },

    canEdit() {
      if (!this.externalLink) {
        return false;
      }
      return this.idsOfOrganisationsIAmAnAdminFor.includes(
        this.externalLink["$v"]["ownerId"]
      );
    },
  },
};
</script>

<style lang="scss">
.view-admin-guidance-external-link {
  padding-bottom: 40px;

  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .button-margin-left {
    margin-left: 10px;
  }

  .external-link-media-wrapper {
    min-height: 700px;
    background: #fff;
    padding: 10px;
    margin-top: 5px;
    border-radius: 5px;
  }
}
</style>